import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { config } from "../../Constants";
import { addNewMessagesToTheQueryData } from "./message-page";
import { postOrThrowWithAuth, useGetMe } from "../../queries";
import { PrimaryButton, SubscribeButton } from "../../components/Buttons";
import AddBuddyModal from "../../components/AddBuddyModal";
import { Spinner } from "../../components/Icons";

function CallToActionMessage({
  content,
  conversationId,
  messageId,
}: {
  content: any;
  conversationId: number;
  messageId: number;
}) {
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { data: user } = useGetMe();

  const completeActionMutation = useMutation({
    mutationFn: (jsonData: any) =>
      postOrThrowWithAuth(
        new URL(`${config.API_URL}/chat/action/completed`),
        jsonData,
      ),
    onSuccess: (res) => {
      setSubmitting(false);
      res.json().then((newMessages) => {
        addNewMessagesToTheQueryData(queryClient, newMessages, true);
        queryClient.invalidateQueries({ queryKey: ["messages"] });
        queryClient.invalidateQueries({ queryKey: ["me"] });
      });
    },
  });
  const skipActionMutation = useMutation({
    mutationFn: (jsonData: any) =>
      postOrThrowWithAuth(
        new URL(`${config.API_URL}/chat/action/skipped`),
        jsonData,
      ),
    onSuccess: (res) => {
      setSubmitting(false);
      res.json().then((newMessages) => {
        addNewMessagesToTheQueryData(queryClient, newMessages, true);
        queryClient.invalidateQueries({ queryKey: ["messages"] });
        queryClient.invalidateQueries({ queryKey: ["me"] });
      });
    },
  });

  function handleCompleteAction() {
    setSubmitting(true);
    var jsonObject: any = {
      cv_id: conversationId,
      me_id: messageId,
      action: content.action,
    };
    completeActionMutation.mutate(jsonObject);
  }
  function handleSkipAction() {
    setSubmitting(true);
    var jsonObject: any = {
      cv_id: conversationId,
      me_id: messageId,
      action: content.action,
    };
    skipActionMutation.mutate(jsonObject);
  }

  if (!user) return <></>;

  if (content.action == "subscribe") {
    return <SubscribeButton />;
  }

  // TODO: handle the different type of actions. For now there's only one action, so we can skip checking.
  return (
    <div>
      <AddBuddyModal
        open={showModal}
        setOpen={setShowModal}
        user={user}
        buddy={undefined}
        buddyIndex={0}
        onBuddyAdded={(b) => handleCompleteAction()}
      />
      {!submitting && (
        <>
          <PrimaryButton
            onClick={() => setShowModal(true)}
            label={content.label}
            submitting={false}
          />
          <button
            type="button"
            onClick={() => handleSkipAction()}
            className="mt-4 text-center text-blue-link hover:text-blue-700"
          >
            {content.skipLabel}
          </button>
        </>
      )}
      {submitting && <Spinner fillColor="#124ff8" />}
    </div>
  );
}

export default CallToActionMessage;
