import { useEffect, useState, useRef } from "react";
import AppLayout from "../../AppLayout";
import { useGetMe, useGetPastWeeklyPlans } from "../../queries";
import { User, WeeklyPlan, DAYS, isUserSubscribed } from "../../types";
import { WeeklyProgram } from "../../components/WeeklyProgram";
import { config } from "../../Constants";
import { Link, useNavigate } from "react-router-dom";
import { Crown, Fire, Spinner, Streak15 } from "../../components/Icons";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { ProgressModal } from "../../components/ProgressModal";
import { WeekProgressBar } from "../../components/ProgressBar";
import {
  currentWeekStreak,
  isThisWeekStreakRequirementMet,
  weekDoneTaskCount,
  weekTotalTaskCount,
} from "../../week-progress-util";
import { PiPencilSimpleLineFill } from "react-icons/pi";
import { WeekStreakModal } from "../../components/WeekStreakModal";
import { ArticleButtonWithBorderAndCheckmark } from "../../components/ArticleComponent";
import { PaywallInlineIcon } from "../../components/Buttons";
import { PaywallButton } from "../../components/PaywallModal";

function ProgressPercentage({ percentage }: { percentage: number }) {
  return (
    <div className="flex flex-row gap-1 items-center">
      <svg
        aria-hidden="true"
        role="status"
        className="inline w-4 h-4"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="8" fill="white" />
        <path
          d="M5 8L7 10L11 6"
          stroke="#F179B0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div className="text-white font-semibold">{`${percentage}%`}</div>
    </div>
  );
}

function calculatePlanCompletionPercentage(user: User) {
  const totalCount = weekTotalTaskCount(user);
  const doneCount = weekDoneTaskCount(user);
  if (doneCount >= totalCount) {
    return 100;
  }
  return Math.min(Math.round((doneCount / totalCount) * 100), 100);
}

function PlanHeader({ user }: { user: User }) {
  const completionPercentage = calculatePlanCompletionPercentage(user);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [showWeekStreakModal, setShowWeekStreakModal] = useState(false);
  const streak = currentWeekStreak(user);
  const colorGradient = user.experiments.includes("alt-color-061024")
    ? "from-[#064E3B] via-[#086F54] to-[#0B8D6B]"
    : "from-[#CD97F9] via-[#EA7CC2] to-[#FF779B]";

  return (
    <>
      <ProgressModal
        user={user}
        show={showProgressModal}
        onClose={() => setShowProgressModal(false)}
      />
      <WeekStreakModal
        show={showWeekStreakModal}
        user={user}
        isRewardScreen={false}
        onClose={() => setShowWeekStreakModal(false)}
      />
      <div
        className={`flex flex-col gap-0 text-white border-b border-main-white bg-gradient-to-r ${colorGradient}`}
      >
        <div className="h-[72px]" />
        <div className="flex flex-col px-4 mb-5 gap-6">
          <div className="flex justify-between font-semibold">
            <button
              type="button"
              className="bg-black bg-opacity-20 rounded-full px-3 py-2"
              onClick={() => setShowProgressModal(true)}
            >
              <div className="flex flex-row items-center gap-1">
                <Fire />
                <div>{user.fires}</div>
              </div>
            </button>
            <button
              type="button"
              className="bg-black bg-opacity-20 rounded-full px-3 py-2"
              onClick={() => setShowProgressModal(true)}
            >
              <div className="flex flex-row items-center gap-1">
                <Crown />
                <div>Level {user.level}</div>
              </div>
            </button>
            <button
              type="button"
              className="bg-black bg-opacity-20 rounded-full px-3 py-2"
              onClick={() => setShowProgressModal(true)}
            >
              <div className="flex flex-row items-center gap-1">
                <div>
                  <Streak15 /> {streak} {streak == 1 ? "week" : "weeks"} streak
                </div>
              </div>
            </button>
          </div>
          <div className="flex flex-col gap-0">
            <div className="flex flex-row justify-between">
              <div className="font-semibold">This Week Progress</div>
              <ProgressPercentage percentage={completionPercentage} />
            </div>
            <WeekProgressBar
              percentage={completionPercentage}
              isWeekStreakRequirementMet={isThisWeekStreakRequirementMet(user)}
              onClick={() => setShowWeekStreakModal(true)}
            />
          </div>
        </div>
        <div className="w-full h-4 bg-main-white rounded-t-lg"> </div>
      </div>
    </>
  );
}

function WeekNavigator({
  currentIndex,
  totalWeeks,
  onNavigate,
}: {
  currentIndex: number;
  totalWeeks: number | null;
  onNavigate: (index: number) => void;
}) {
  const getWeekLabel = (index: number) => {
    if (index === 0) return "Current week";
    return `${index} week${index > 1 ? "s" : ""} ago`;
  };

  if (!totalWeeks) return null;

  return (
    <div className="flex items-center gap-2">
      {totalWeeks > 1 && currentIndex < totalWeeks - 1 && (
        <button
          onClick={() => onNavigate(currentIndex + 1)}
          className="w-4 h-4"
        >
          <FaArrowLeft />
        </button>
      )}

      {currentIndex > 0 && (
        <button
          onClick={() => onNavigate(0)}
          className="text-sm text-blue-link hover:underline"
        >
          Go to current week
        </button>
      )}

      <div className="text-sm">{`${getWeekLabel(currentIndex)}`}</div>

      {currentIndex > 0 && (
        <button
          onClick={() => onNavigate(currentIndex - 1)}
          className="w-4 h-4"
        >
          <FaArrowRight />
        </button>
      )}
    </div>
  );
}

function WeekProgramSection({ user }: { user: User }) {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const hasSubscription: boolean = isUserSubscribed(user);

  const navigate = useNavigate();

  const {
    data: pastPlans,
    refetch,
    isLoading,
    isError,
  } = useGetPastWeeklyPlans();

  const handleNavigate = async (index: number) => {
    setCurrentIndex(index);

    if (index > 0 && (!pastPlans || pastPlans.length === 0)) {
      await refetch();
    }
  };

  if (!user.weeklyPlan) {
    return (
      <div>
        <h4 className="text-lg font-semibold mb-2">This week program</h4>
        <div className="italic">
          Program not yet determined - Work with your coach to define it!
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="font-semibold mb-2 flex justify-between">
        <h4 className="text-lg">This week</h4>
        <WeekNavigator
          currentIndex={currentIndex}
          totalWeeks={user.weekCount}
          onNavigate={handleNavigate}
        />
      </div>

      {currentIndex === 0 ? (
        <>
          <div className="p-0">
            <WeeklyProgram
              user={user}
              weeklyPlan={user.weeklyPlan}
              showStatus
            />
          </div>

          <PaywallButton
            enablePaywall={true}
            onClick={() => {
              navigate("/chat?edit_plan=1");
            }}
          >
            <div className="flex justify-center items-center h-[44px] rounded-lg w-full bg-button-blue font-medium text-white">
              {hasSubscription && (
                <PiPencilSimpleLineFill className="inline mb-[2px]" />
              )}
              {!hasSubscription && <PaywallInlineIcon color="white" />}
              &nbsp;Edit My Plan
            </div>
          </PaywallButton>
        </>
      ) : isLoading ? (
        <div className="flex flex-col items-center justify-center h-full">
          <Spinner fillColor="#666f8a" />
          <div>Loading past weekly plans...</div>
        </div>
      ) : isError ? (
        <div className="flex flex-col justify-center w-full">
          Error loading past weekly plans. Please try again later.
        </div>
      ) : (
        pastPlans && (
          <>
            <div className="p-0">
              <WeeklyProgram
                user={user}
                weeklyPlan={pastPlans?.[currentIndex]}
                showStatus
              />
            </div>

            {pastPlans?.[currentIndex]?.feedback && (
              <div className="px-9 pt-5">
                <h6 className="font-semibold">Your end-of-week feedback:</h6>
                <p className="text-sm break-words">
                  {pastPlans?.[currentIndex]?.feedback}
                </p>
              </div>
            )}
          </>
        )
      )}
    </>
  );
}

function PlanComponent({ user }: { user: User }) {
  const hasSubscription: boolean = isUserSubscribed(user);
  const profileSetUp: boolean =
    user.name != null &&
    (user.currentWeightKg != null || user.currentWeightLbs != null);

  const [showWeekStreakRewardModal, setShowWeekStreakRewardModal] =
    useState(false);
  // Used to show the reward screen only if the state of isRewardedThisWeekStreak
  // changes while this component is mounted.
  const weekStreakRewardedPreviousState = useRef(user.isRewardedThisWeekStreak);

  useEffect(() => {
    if (
      !weekStreakRewardedPreviousState.current &&
      user.isRewardedThisWeekStreak &&
      user.previousWeeksStreak === 1
    ) {
      setShowWeekStreakRewardModal(true);
      weekStreakRewardedPreviousState.current = user.isRewardedThisWeekStreak;
    }
  }, [user.isRewardedThisWeekStreak]);

  const exerciseArticles = user.articles.filter(
    (article) => !article.metadata.tags.includes("nutrition"),
  );

  return (
    <div
      className="bg-main-white flex-grow overflow-y-auto pb-20"
      id="profile_page_content"
    >
      <PlanHeader user={user} />
      <div className="flex flex-col gap-6 px-4 pt-4">
        {!user.weeklyPlan && (
          <div className="font-semibold text-center">
            <p>
              <Link className="text-blue-link underline" to="/chat">
                Work with your coach
              </Link>
            </p>
            <p>to create your weekly program!</p>
          </div>
        )}
        {user.weeklyPlan && (
          <>
            {exerciseArticles.map((article) => (
              <ArticleButtonWithBorderAndCheckmark
                key={article.metadata.id}
                article={article}
              />
            ))}
          </>
        )}
        {user.weeklyPlan && <WeekProgramSection user={user} />}
        {user.weeklyPlan && hasSubscription && (
          <WeekStreakModal
            show={showWeekStreakRewardModal}
            user={user}
            isRewardScreen={true}
            onClose={() => setShowWeekStreakRewardModal(false)}
          />
        )}
      </div>
    </div>
  );
}

function PlanPage() {
  const { data: user, isLoading: userIsLoading } = useGetMe();

  if (userIsLoading) return <></>;

  if (!user) {
    window.location.href = `${config.API_URL}/login?page=/plan`;
    return <></>;
  }

  return (
    <AppLayout user={user}>
      <PlanComponent user={user} />
    </AppLayout>
  );
}

export default PlanPage;
