import { useState, useRef, useEffect } from "react";
import { Modal } from "flowbite-react";
import parse from "html-react-parser";
import { useQueryClient } from "@tanstack/react-query";
import Markdown, { defaultUrlTransform } from "react-markdown";
import {
  useGetDayDetails,
  useGetMe,
  useCompleteDailyPlanMutation,
} from "../queries";
import { WeeklyPlan, WeeklyPlanDay, DAYS, User } from "../types";
import { PaywallInlineIcon, PrimaryButton } from "./Buttons";
import { PaywallButton } from "./PaywallModal";
import {
  DoneStatusButtonStyle,
  DailyPlanDoneStatusButton,
} from "./TaskDoneStatus";
import { ftToCm } from "../utils";
import { PiMoonStarsFill } from "react-icons/pi";
import { COMPONENTS } from "../markdown";
import { config } from "../Constants";

function addDaysToDate(originalDate: Date, daysToAdd: number): Date {
  const resultDate = new Date(originalDate);
  resultDate.setDate(resultDate.getDate() + daysToAdd);
  return resultDate;
}

function calculateCaloriesBurned(user: User, day: WeeklyPlanDay): number[] {
  let result = new Array(day.tasks.length).fill(0);
  if (day.tasks.length == 0) {
    return result;
  }
  if (!day.duration || !day.met) {
    return result;
  }
  if (
    day.tasks.length != day.duration.length ||
    day.tasks.length != day.met.length
  ) {
    return result;
  }
  if (!user.currentWeightKg && !user.currentWeightLbs) {
    return result;
  }
  if (!user.heightCm && !user.heightFt) {
    return result;
  }
  if (!user.age) {
    return result;
  }
  const weightKg = user.currentWeightKg || user.currentWeightLbs! / 2.20462;
  const heightCm = user.heightCm || ftToCm(user.heightFt!);
  // Mifflin-St Jeor Equations for BMR
  const bmr =
    user.gender == "male"
      ? 10 * weightKg + 6.25 * heightCm - 5 * user.age + 5
      : 10 * weightKg + 6.25 * heightCm - 5 * user.age - 161;
  const bmrPerMinute = bmr / 1440;
  for (let i = 0; i < day.tasks.length; i++) {
    if (day.duration[i] == 0) {
      continue;
    }
    const calories = day.duration[i] * day.met[i] * bmrPerMinute;
    const caloriesRounded = calories > 40 ? 10 * Math.round(calories / 10) : 0;
    result[i] = caloriesRounded;
  }
  return result;
}

function isCurrentWeek(user: User, week: Date): boolean {
  const today = new Date();
  const weekStart = addDaysToDate(week, 0);
  const weekEnd = addDaysToDate(week, 6);
  return weekStart <= today && today <= weekEnd;
}

function WeeklyProgramDayDate({
  dayOfWeek,
  week,
}: {
  dayOfWeek: string;
  week: Date;
}) {
  const today = new Date();
  const thisDay = addDaysToDate(week, DAYS.indexOf(dayOfWeek));
  const isToday = thisDay.getUTCDate() == today.getDate();

  return (
    <div className="flex flex-col justify-center items-center gap-[2px] w-[32px] text-xs font-semibold">
      <div className={isToday ? "text-black font-bold" : "text-gray-600"}>
        {dayOfWeek.substr(0, 3).toUpperCase()}
      </div>
      {isToday && (
        <div className="flex justify-center items-center h-[24px] w-[24px] rounded-lg bg-button-blue text-white font-bold mb-1">
          {thisDay.getUTCDate()}
        </div>
      )}
      {!isToday && (
        <div className="flex justify-center items-center h-[24px] w-[24px] rounded-lg bg-button-blue-light text-[#4989E8] font-bold mb-1">
          {thisDay.getUTCDate()}
        </div>
      )}
    </div>
  );
}

function WeeklyProgramDay({
  user,
  weeklyPlan,
  day,
  showStatus,
  onViewDayDetails,
}: {
  user: User;
  weeklyPlan: WeeklyPlan;
  day: string;
  showStatus: boolean;
  onViewDayDetails: (d: string) => void;
}) {
  let entry = (weeklyPlan?.days as any)?.[day];
  // If we are missing a day, it means it was not generated by the LLM because it was a "past day" in the first week.
  let isPastDay: boolean = entry == null;
  const week = new Date(weeklyPlan.week);
  const isCurrentWeek =
    new Date(user?.weeklyPlan?.week).toDateString() ===
    new Date(weeklyPlan?.week).toDateString();
  const calories = entry ? calculateCaloriesBurned(user, entry) : null;
  const requiresSubscription = entry && !entry.isFirstDay;

  return (
    <>
      <div key={day} className={"flex flex-row items-stretch gap-2"}>
        <WeeklyProgramDayDate dayOfWeek={day} week={week} />

        {(isPastDay || entry.tasks.length == 0) && (
          <div className="flex items-center w-full font-semibold text-[#5A5A5A] min-h-[48px] border border-[#898989] rounded-2xl px-[10px] border-dashed bg-white">
            <div className="flex justify-left gap-2 items-center">
              <div className="flex justify-center items-center rounded-full bg-[#1C274C] h-[28px] w-[28px] text-white">
                <PiMoonStarsFill className="inline" />
              </div>
              <div>{isPastDay ? "Past" : "Break"}</div>
            </div>
          </div>
        )}

        {!isPastDay && entry.tasks.length > 0 && (
          <div className="flex items-center w-full font-semibold text-[#5A5A5A] min-h-[48px] border border-[#898989] rounded-2xl p-3 bg-white">
            <div className="grow flex flex-col justify-between">
              <div className="text-sm">
                {entry.tasks.map((task: any, idx: number) => (
                  <div key={idx}>
                    <span>{parse(task)}</span>
                    {calories && calories[idx] > 0 && (
                      <span>{` ~${calories[idx]} kcal.`}</span>
                    )}
                  </div>
                ))}
              </div>
              {entry.tasks.length > 0 && (
                <div className="mt-2 flex flex-row justify-between">
                  <PaywallButton
                    enablePaywall={requiresSubscription}
                    onClick={() => onViewDayDetails(day)}
                  >
                    {requiresSubscription && (
                      <PaywallInlineIcon suffix=" " color="#757575" />
                    )}
                    <span className="underline text-gray-600">Details</span>
                  </PaywallButton>
                  {showStatus && isCurrentWeek && (
                    <DailyPlanDoneStatusButton
                      day={day}
                      week={null}
                      style={DoneStatusButtonStyle.WeeklyPlan}
                      requiresSubscription={requiresSubscription}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export function WeeklyProgram({
  user,
  weeklyPlan,
  showStatus,
}: {
  user: User;
  weeklyPlan: WeeklyPlan;
  showStatus: boolean;
}) {
  const [showDetailsDay, setShowDetailsDay] = useState<string | undefined>(
    undefined,
  );
  const queryClient = useQueryClient();

  const resetCacheAndSetShowDetailsDay = (day: string) => {
    queryClient.resetQueries({
      queryKey: ["weekly-plan", day, "details"],
      exact: true,
    });
    setShowDetailsDay(day);
  };

  const pastDaysCount = DAYS.filter(
    (day) => (weeklyPlan?.days as any)?.[day] == null,
  ).length;

  return (
    <>
      <WeeklyProgramDetailsModalWithAudio
        day={showDetailsDay}
        onClose={() => setShowDetailsDay(undefined)}
      />
      {pastDaysCount >= 4 && (
        <div className="text-xs mb-3">
          This week is short! Next week we’ll start on Monday
        </div>
      )}
      <div className="flex flex-col gap-4">
        {DAYS.map((day) => (
          <WeeklyProgramDay
            user={user}
            key={day}
            day={day}
            weeklyPlan={weeklyPlan}
            showStatus={showStatus}
            onViewDayDetails={(day) => resetCacheAndSetShowDetailsDay(day)}
          />
        ))}
      </div>
    </>
  );
}

export function LoadingSpinner() {
  return (
    <div role="status">
      <svg
        aria-hidden="true"
        className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
}

function WeeklyProgramDetailsModal({
  open,
  onClose,
  day,
}: {
  open: boolean;
  onClose: (markedAsDone: boolean) => void;
  day: string;
}) {
  const query = useGetDayDetails(day);
  const me = useGetMe();
  const markDoneMutation = useCompleteDailyPlanMutation();
  const isDone = (me.data?.weeklyPlan?.days as any)?.[day]?.done;
  const buttonLabel = isDone ? "Done" : "Mark as Done";
  const donePressed = useRef(false);

  const onDoneClick = () => {
    if (me.isPending || markDoneMutation.isPending) {
      return;
    }

    if (isDone) {
      onClose(false);
    } else {
      markDoneMutation.mutate({ day: day, done: true });
      donePressed.current = true;
    }
  };

  useEffect(() => {
    if (donePressed.current && markDoneMutation.isSuccess) {
      donePressed.current = false;
      onClose(true);
    }
  }, [markDoneMutation.isSuccess]);

  const transformImageUri = (uri: string, key: string, element: any) => {
    if (element.tagName === "img") {
      return defaultUrlTransform(`${config.API_URL}/static/images/${uri}`);
    } else {
      return defaultUrlTransform(uri);
    }
  };

  return (
    <Modal dismissible={true} show={open} onClose={() => onClose(false)}>
      <Modal.Header>
        <span className="font-semibold">{day}</span>
      </Modal.Header>
      <Modal.Body>
        {query.isLoading && (
          <div className="flex flex-row justify-center content-stretch items-center">
            <LoadingSpinner />
          </div>
        )}
        {!query.isLoading && query.data && (
          <div>
            <Markdown urlTransform={transformImageUri} components={COMPONENTS}>
              {query.data}
            </Markdown>
          </div>
        )}

        <div className="flex flex-col w-full mt-8">
          <PrimaryButton
            onClick={onDoneClick}
            label={buttonLabel}
            submitting={me.isPending || markDoneMutation.isPending}
          />
          <PrimaryButton
            onClick={() => onClose(false)}
            label={"✕ Close"}
            submitting={false}
            variant="tertiary"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function WeeklyProgramDetailsModalWithAudio({
  day,
  onClose,
}: {
  day: string | undefined;
  onClose: () => void;
}) {
  const audioRef = useRef<HTMLAudioElement>(null);

  const onPlanDetailsClosed = (markedAsDone: boolean) => {
    if (markedAsDone && audioRef.current) {
      audioRef.current.play();
    }
    onClose();
  };

  return (
    <>
      {day && (
        <WeeklyProgramDetailsModal
          day={day}
          open={day !== undefined}
          onClose={onPlanDetailsClosed}
        />
      )}
      <audio ref={audioRef} src="/static/on-done.mp3" />
    </>
  );
}
