import { useRef, useState } from "react";
import { config } from "../../Constants";
import { Spinner } from "../../components/Icons";
import { fetchOrThrowWithAuth } from "../../queries";

function DeleteMePage() {
  const reasonRef = useRef<HTMLTextAreaElement>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDeleteMePressed = () => {
    if (isDeleting) return;
    setIsDeleting(true);
    fetchOrThrowWithAuth(new URL(`${config.API_URL}/delete-me`), "DELETE", {
      reason: reasonRef.current?.value,
    }).then((res) => {
      if (!res.ok) {
        return alert("Failed to delete account");
      }
      window.location.href = `${config.API_URL}/logout`;
    });
  };

  return (
    <div className="flex flex-col h-full items-center justify-center p-4">
      <div className="font-semibold text-[24px] mt-[50px]">
        We are sad to see you go 😢
      </div>

      <div className="flex flex-col gap-1 w-[100%] mt-12">
        <p className="text-gray-800">Please tell us why you are leaving</p>
        <textarea
          ref={reasonRef}
          className="w-[95%] p-2 border border-gray-600 rounded-lg text-gray-800"
          placeholder="Reason for leaving..."
          maxLength={3000}
          rows={4}
        />
      </div>

      <div className="w-[100%] text-sm text-gray-800 mt-12">
        <p>
          Are you sure you want to delete your account? You will lose all your
          progress and data.
        </p>
      </div>

      {!isDeleting && (
        <button
          type="button"
          className="flex items-center justify-center h-[30px] w-[90%] max-w-[400px] text-red-600 mt-4"
          onClick={onDeleteMePressed}
        >
          Yes, delete my account
        </button>
      )}

      {isDeleting && (
        <div className="flex items-center justify-center h-[30px] w-[90%] max-w-[400px] mt-4 text-red-600">
          <Spinner fillColor="#E50000" /> Deleting...
        </div>
      )}

      {!isDeleting && (
        <a
          className="flex items-center justify-center h-[44px] w-[90%] max-w-[400px] bg-[#4989E8] text-white rounded-lg mt-4"
          href="/profile"
        >
          I changed my mind
        </a>
      )}
    </div>
  );
}

export default DeleteMePage;
