import { Preferences } from "@capacitor/preferences";
import { Capacitor } from "@capacitor/core";

export async function getAuthToken() {
  if (Capacitor.isNativePlatform()) {
    const { value } = await Preferences.get({ key: "auth_token" });
    return value ? JSON.parse(value) : null;
  }
  return null;
}
