import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Capacitor } from "@capacitor/core";

import { config } from "./Constants";
import { User, NutritionRecommendation, Recipe, WeeklyPlan } from "./types";
import { addNewMessagesToTheQueryData } from "./pages/chat/message-page";
import { getAuthToken } from "./mobile";

class FetchError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message); // Call the base Error constructor
    this.name = "FetchError";
    this.response = response;

    // Set the prototype explicitly (necessary for proper inheritance)
    Object.setPrototypeOf(this, FetchError.prototype);
  }
}

export async function fetchOrThrowWithAuth(
  url: URL,
  method: string,
  object: any = undefined,
  options: RequestInit = {},
): Promise<Response> {
  const authToken = await getAuthToken();

  const isMobile = Capacitor.isNativePlatform();
  if (isMobile && !url.searchParams.has("is_mobile")) {
    url.searchParams.append("is_mobile", "true");
  }

  if (object) {
    options = {
      ...options,
      headers: {
        ...options.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(object),
    };
  }

  if (authToken) {
    options = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${authToken}`,
      },
    };
  } else {
    options = {
      ...options,
      credentials: "include",
    };
  }

  return fetch(url, {
    ...options,
    method: method,
  }).then((res) => {
    if (!res.ok) {
      throw new FetchError(
        `Unexpected result for ${url}: ${res.status} ${res.statusText}`,
        res,
      );
    }
    return res;
  });
}

export function getOrThrowWithAuth(
  url: URL,
  object: any = undefined,
): Promise<Response> {
  return fetchOrThrowWithAuth(url, "GET", object);
}

export function postOrThrowWithAuth(
  url: URL,
  object: any = undefined,
): Promise<Response> {
  return fetchOrThrowWithAuth(url, "POST", object);
}

export const useGetMe = () =>
  useQuery<User | undefined>({
    queryKey: ["me"],
    retry: (failureCount, error) => {
      if (error instanceof FetchError) {
        if (error.response.status >= 400 && error.response.status < 500) {
          return false;
        }
      }
      return failureCount < 3;
    },
    queryFn: async () => {
      try {
        const response = await fetchOrThrowWithAuth(
          new URL(`${config.API_URL}/me`),
          "GET",
        );

        return response.json();
      } catch (error) {
        if (error instanceof FetchError) {
          if (error.response.status === 401) {
            console.log("Not authenticated as a valid user yet");
            return undefined;
          }
        } else {
          console.error("Unknown error fetching user data:", error);
          throw new Error("Unknown error fetching user data");
        }
      }
    },
  });

export const useGetDayDetails = (day: string) =>
  useQuery<string>({
    queryKey: ["weekly-plan", day, "details"],
    queryFn: () =>
      getOrThrowWithAuth(
        new URL(`${config.API_URL}/weekly-plan/${day}/details`),
      )
        .then((res) => res.json())
        .then((data) => data.details),
  });

export const useGetPastWeeklyPlans = (weeksAgo?: number) =>
  useQuery<WeeklyPlan[]>({
    queryKey: ["recent-weekly-plans", weeksAgo],
    queryFn: () =>
      fetch(
        `${config.API_URL}/weekly-plans/past${weeksAgo ? "?weeks_ago=" + weeksAgo : ""}`,
        {
          method: "GET",
          credentials: "include",
        },
      ).then((res) => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 404) {
          return []; // Return an empty array if no plans are found
        } else {
          console.error("Unexpected result for /weekly-plans/past:");
          console.error(res);
          throw new Error("Unexpected result for /weekly-plans/past");
        }
      }),
    enabled: false,
  });

export const useEditMe = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newUser: User) =>
      fetchOrThrowWithAuth(
        new URL(`${config.API_URL}/me`),
        "PUT",
        newUser,
      ).then((res) => res.json()),
    onMutate: (data: User) => {
      // TODO: only supported fields should be updated.
      queryClient.setQueryData(["me"], (old: User) => {
        return { ...old, ...data };
      });
    },
    onSuccess: (data: any) => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
    onError: () => {
      // TODO: display an error message (toast) to notify that the operation failed.
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });
};

export const useAddBuddy = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ name, email }: { name: string; email: string }) => {
      return postOrThrowWithAuth(new URL(`${config.API_URL}/buddies`), {
        name: name,
        email: email,
      });
    },
    onMutate: (newBuddy) => {
      queryClient.setQueryData(["me"], (old: User) => {
        let newBuddies: any = old.buddies ? [...old.buddies, newBuddy] : [];

        return { ...old, buddies: newBuddies };
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useEditBuddy = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      index,
      name,
      email,
    }: {
      index: number;
      name: string;
      email: string;
    }) => {
      return fetchOrThrowWithAuth(
        new URL(`${config.API_URL}/buddies/${index}`),
        "PUT",
        {
          name: name,
          email: email,
        },
      );
    },
    onMutate: (newBuddy) => {
      queryClient.setQueryData(["me"], (old: User) => {
        let newBuddies: any = old.buddies.map((b, i) =>
          i == newBuddy.index
            ? { name: newBuddy.name, email: newBuddy.email }
            : b,
        );

        return { ...old, buddies: newBuddies };
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useDeleteBuddy = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ email }: { email: string }) => {
      return fetchOrThrowWithAuth(
        new URL(`${config.API_URL}/buddies`),
        "DELETE",
        {
          email: email,
        },
      );
    },
    onMutate: (buddy) => {
      queryClient.setQueryData(["me"], (old: User) => {
        let newBuddies: any = old.buddies.filter((b) => b.email != buddy.email);
        return { ...old, buddies: newBuddies };
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export function createCheckoutSession(annual: boolean) {
  postOrThrowWithAuth(
    new URL(`${config.API_URL}/create-checkout-session?annual=${annual}`),
  )
    .then((response) => response.json())
    .then((data) => {
      if (data.url) {
        window.location.href = data.url;
      } else {
        console.error("Error creating checkout session:", data);
      }
    })
    .catch((error) => console.error("Error:", error));
}

export function useSendMessageMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      content,
      conversationId,
      callback,
    }: {
      content: string;
      conversationId: number;
      callback?: string;
    }) =>
      postOrThrowWithAuth(new URL(`${config.API_URL}/message`), {
        conversationId: conversationId,
        content: content,
        callback: callback,
      }).then((res) => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 401) {
          console.error("Not authenticated as a valid user yet");
          console.error(res);
          throw new Error("Unauthorized");
        } else {
          console.error("Unexpected result for POST /message:");
          console.error(res);
          throw new Error("Unexpected result for POST /message");
        }
      }),
    onMutate: async (newMessage) => {
      await queryClient.cancelQueries({ queryKey: ["messages"] });

      addNewMessagesToTheQueryData(
        queryClient,
        [
          {
            ...newMessage,
            id: -1,
            messengerRole: "user",
            postedAt: undefined,
            submitted: false,
            formattedContent: undefined,
          },
        ],
        false,
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["messages"] });
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
    onSuccess: (res) => {
      addNewMessagesToTheQueryData(queryClient, res, true);
      queryClient.invalidateQueries({ queryKey: ["messages"] });
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });
}

export function useRegenerateMenuMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () =>
      postOrThrowWithAuth(new URL(`${config.API_URL}/regenerate-menu`)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["nutrition"] });
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["nutrition"] });
    },
  });
}

export function useGetNutritionRecommendationQuery() {
  return useQuery<NutritionRecommendation>({
    queryKey: ["nutrition"],
    queryFn: () =>
      getOrThrowWithAuth(new URL(`${config.API_URL}/nutrition`)).then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.error("Unexpected result for /nutrition:", res);
          throw new Error("Unexpected result for /nutrition");
        }
      }),
    retry: 1,
  });
}

export function useGetRecipeDetailsQuery(recipeId: string) {
  return useQuery<Recipe>({
    queryKey: ["recipe", recipeId],
    queryFn: () =>
      getOrThrowWithAuth(
        new URL(`${config.API_URL}/recipe-details?id=${recipeId}`),
      ).then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.error("Unexpected result for /recipe:", res);
          throw new Error("Unexpected result for /recipe");
        }
      }),
  });
}

function dailyPlanDoneStatusUrl(day: string, week: string | null): URL {
  let url = new URL(`${config.API_URL}/weekly-plan/completed`);
  url.searchParams.append("day", day);
  if (week) url.searchParams.append("week", week);
  return url;
}

function userWithDailyPlanDoneStatus(
  user: User,
  day: string,
  done: boolean,
): User {
  if (!user.weeklyPlan) {
    return user;
  }
  if ((user.weeklyPlan.days as any)?.[day]?.done === done) {
    return user;
  }
  let newDays: any = { ...user.weeklyPlan.days };
  newDays[day] = {
    ...newDays[day],
    done: done,
  };
  const newFires = done ? user.fires + 1 : user.fires - 1;
  return {
    ...user,
    fires: newFires,
    weeklyPlan: { ...user.weeklyPlan, days: newDays },
  };
}

export function useCompleteDailyPlanMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ day, done }: { day: string; done: boolean }) => {
      let url = dailyPlanDoneStatusUrl(day, null);
      url.searchParams.append("done", done ? "1" : "0");
      return postOrThrowWithAuth(url);
    },
    onError: (error: Error, variables: { day: string; done: boolean }) => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
    onSuccess: (
      response: Response,
      variables: { day: string; done: boolean },
    ) => {
      queryClient.setQueryData(["me"], (old: User) => {
        return userWithDailyPlanDoneStatus(old, variables.day, variables.done);
      });
      queryClient.invalidateQueries({ queryKey: ["me"] });
      response.json().then((newMessages) => {
        addNewMessagesToTheQueryData(queryClient, newMessages, true);
      });
    },
  });
}

export function useGetArticleMarkdownContent(articleId: string) {
  const url = `${config.API_URL}/static/library/${articleId}/content.md`;
  return useQuery<string>({
    queryKey: ["article-content", articleId],
    queryFn: () =>
      getOrThrowWithAuth(new URL(url)).then((res) => {
        if (res.ok) {
          const text = res.text();
          console.log(`Article content for ${articleId}:`, text);
          return text;
        } else {
          console.error(`Unexpected result for ${url}:`, res);
          throw new Error(`Unexpected result for ${url}`);
        }
      }),
  });
}

function userWithArticleMarkedAsRead(user: User, articleId: string): User {
  let article = user.articles.find((a) => a.metadata.id === articleId);
  let wasReadAt = article?.state.wasReadAt;
  if (!article || wasReadAt) {
    return user;
  }

  let newArticles = user.articles.map((a) =>
    a.metadata.id === articleId ? { ...a, wasReadAt: new Date() } : a,
  );
  return { ...user, articles: newArticles, fires: user.fires + 1 };
}

export function useMarkArticleAsReadMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ articleId }: { articleId: string }) => {
      let url = new URL(`${config.API_URL}/mark-article-read`);
      url.searchParams.append("id", articleId);
      return postOrThrowWithAuth(url);
    },
    onError: (error: Error, variables: { articleId: string }) => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
    onSuccess: (response: Response, variables: { articleId: string }) => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
      queryClient.setQueryData(["me"], (old: User) =>
        userWithArticleMarkedAsRead(old, variables.articleId),
      );
    },
  });
}
