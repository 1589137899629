import { useEffect, useMemo, useRef, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import parse from "html-react-parser";

import {
  getOrThrowWithAuth,
  useGetMe,
  useSendMessageMutation,
} from "../../queries";
import { config } from "../../Constants";
import { Message } from "../../types";
import { MessagePage, MessagePageIndex } from "./message-page";

import ConversationBlock from "./ConversationBlock";
import MessagingBlock from "./MessagingBlock";
import AppLayout from "../../AppLayout";

import { Capacitor } from "@capacitor/core";

const isMobile = Capacitor.isNativePlatform();

function ChatPage() {
  const [
    waitingForResponseViaMessagingBlock,
    setWaitingForResponseViaMessagingBlock,
  ] = useState<boolean>(false);
  const [
    waitingForResponseViaSuggestionChip,
    setWaitingForResponseViaSuggestionChip,
  ] = useState<boolean>(false);
  const waitingForResponse =
    waitingForResponseViaMessagingBlock || waitingForResponseViaSuggestionChip;

  const sendSuggestionChipMessageMutation = useSendMessageMutation();

  useEffect(() => {
    setWaitingForResponseViaSuggestionChip(
      sendSuggestionChipMessageMutation.isPending,
    );
  }, [sendSuggestionChipMessageMutation.isPending]);

  // TODO: change isLoading to isPending(?): https://github.com/TanStack/query/discussions/6297
  const { data: user, isLoading: userIsLoading } = useGetMe();

  // Somewhat hacky way to detect if new pages are loaded,
  // or if it's optimistic mutation to know when the user has added a new message
  const didLoadNewPages = useRef(true);

  const parsePageResponse = (response: any): MessagePage => {
    return {
      hasNext: response.hasNext,
      messages: response.messages.map((msg: any) => ({
        ...msg,
        formattedContent: msg.formattedContent
          ? parse(msg.formattedContent)
          : undefined,
        postedAt: msg.postedAt ? new Date(msg.postedAt) : undefined,
      })) as Message[],
    } as MessagePage;
  };

  const fetchMessagePage = async ({
    pageParam,
  }: {
    pageParam: any;
  }): Promise<MessagePage> => {
    const pageIndex = pageParam as MessagePageIndex;
    let url = new URL(`${config.API_URL}/messages`);
    if (pageIndex.begin)
      url.searchParams.append("begin", pageParam.begin.toString());
    if (pageIndex.end) url.searchParams.append("end", pageParam.end.toString());
    return getOrThrowWithAuth(url).then((res) => {
      if (res.ok) {
        didLoadNewPages.current = true;
        return res.json().then((response: any) => parsePageResponse(response));
      } else {
        console.error("Unexpected result for /messages");
        console.error(res);
        throw new Error(
          `Unexpected result for /messages: ${res.url} ${res.status} ${res.statusText}`,
        );
      }
    });
  };

  const {
    data: pagesData,
    isLoading: messagesAreLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<MessagePage>({
    queryKey: ["messages"],
    queryFn: fetchMessagePage,
    initialPageParam: {},
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.hasNext && lastPage.messages.length > 0
        ? { end: lastPage.messages[0].id }
        : undefined;
    },
  });

  const messages = useMemo(
    () =>
      pagesData
        ? pagesData.pages
            .slice()
            .reverse()
            .flatMap((page) => (page ? page.messages : []))
        : undefined,
    [pagesData],
  );

  const firstNewMessageIdx = useMemo(
    () => messages?.findIndex((message) => message.isNew === true),
    [messages],
  );

  const messageCount = messages?.length;

  useEffect(() => {
    if (!firstNewMessageIdx) return;
    // New messages are added, scroll to the first new message
    const elementId = "dialog-" + firstNewMessageIdx.toString();
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [firstNewMessageIdx]);

  useEffect(() => {
    if (!waitingForResponse) return;
    // User has typed something and waiting for response, scroll to the bottom.
    const pageContent = document.getElementById("chat_page_content");
    if (!pageContent) return;
    pageContent.scrollTo({ top: pageContent.scrollHeight, behavior: "smooth" });
    window.sessionStorage.setItem("chat_scroll_from_bottom", "0");
  }, [waitingForResponse]);

  useEffect(() => {
    if (userIsLoading) return;
    if (!messages) return;
    const pageContent = document.getElementById("chat_page_content");
    if (!pageContent) return;
    if (!didLoadNewPages.current) return;

    // Message count has changed, it can happen in 3 cases:
    // 1. User has sent a message and we have optimistically updated the
    //   message list, in that case didLoadNewPages should be false.
    //   Don't restore scroll, another hook will handle it.
    // 2. User has loaded more messages by clicking the "Load More" button.
    //   Restore scroll, otherwise the scroll position will jump up, as new messages
    //   are added at the top.
    // 3. The component has just loaded, restore scroll to the last position.
    let scrollFromBottom = 0;
    const savedScroll = window.sessionStorage.getItem(
      "chat_scroll_from_bottom",
    );
    if (savedScroll) {
      scrollFromBottom = parseInt(savedScroll);
    }
    pageContent.scrollTop = pageContent.scrollHeight - scrollFromBottom;
    didLoadNewPages.current = false;
  }, [messageCount, userIsLoading]);

  useEffect(() => {
    // Scroll position saving hook.
    if (messagesAreLoading) return;

    const pageContent = document.getElementById("chat_page_content");
    if (!pageContent) return;

    const onScroll = () => {
      const scrollFromBottom = pageContent.scrollHeight - pageContent.scrollTop;
      window.sessionStorage.setItem(
        "chat_scroll_from_bottom",
        scrollFromBottom.toString(),
      );
    };
    // code for clean up
    pageContent.removeEventListener("scroll", onScroll);
    pageContent.addEventListener("scroll", onScroll, { passive: true });
    return () => pageContent.removeEventListener("scroll", onScroll);
  }, [userIsLoading, messagesAreLoading]);

  if (userIsLoading) return <></>;

  if (!user) {
    window.location.href = `${config.API_URL}/login?page=/chat&is_mobile=${isMobile}`;
    return <></>;
  }

  const onSuggestionChipClick = (chip: string) => {
    if (waitingForResponse) return;
    if (!messages) return;
    sendSuggestionChipMessageMutation.mutate({
      content: chip,
      conversationId: messages[messages.length - 1].conversationId,
      callback: messages[messages.length - 1].callback,
    });
  };

  return (
    <AppLayout user={user}>
      <div
        className="bg-main-white flex-grow overflow-y-auto px-4 pt-20 pb-20"
        id="chat_page_content"
      >
        {messages && (
          <>
            <div className="text-sm mb-10 text-slate-700">
              ⓘ Disclaimer: the content is generated using AI. It might make
              mistake and give inappropriate information. Don’t rely on
              responses for medical or other professional advice.
            </div>

            {hasNextPage && (
              <div className="flex justify-center">
                <button
                  className="w-32 h-10 mx-4 mb-10"
                  onClick={() => fetchNextPage()}
                  disabled={isFetchingNextPage}
                >
                  {isFetchingNextPage ? "Loading more..." : "Load More"}
                </button>
              </div>
            )}

            <ConversationBlock
              dialog={messages}
              coachIsWriting={waitingForResponse}
              onSuggestionChipClick={onSuggestionChipClick}
              user={user}
            />

            <div className="fixed bottom-0 left-0 right-0 z-20 backdrop-blur-sm bg-main-white/75 md:left-1/2 md:w-1/2 md:transform md:-translate-x-1/2">
              <MessagingBlock
                disabled={waitingForResponse}
                hidden={
                  messages.length > 0 &&
                  (messages[messages.length - 1].messageType === "form" ||
                    messages[messages.length - 1].messageType ===
                      "call_to_action")
                }
                conversationId={messages[messages.length - 1].conversationId}
                callback={messages[messages.length - 1].callback}
                setSendingMessage={setWaitingForResponseViaMessagingBlock}
              />
            </div>
          </>
        )}
      </div>
    </AppLayout>
  );
}

export default ChatPage;
