import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Preferences } from "@capacitor/preferences";
import { config } from "../Constants";
import { postOrThrowWithAuth } from "../queries";

export const useExchangeCodeForToken = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (oneTimeCode: string) => {
      const response = await postOrThrowWithAuth(
        new URL(`${config.API_URL}/exchange-code`),
        {
          one_time_code: oneTimeCode,
        },
      );

      response
        .clone()
        .text()
        .then((bodyText) => {
          console.log(
            "Response details:",
            JSON.stringify(
              {
                status: response.status,
                statusText: response.statusText,
                headers: Object.fromEntries(response.headers.entries()),
                body: bodyText,
              },
              null,
              2,
            ),
          );
        })
        .catch((error) => {
          console.error("Error reading response:", error);
        });

      if (!response.ok) {
        throw new Error("Failed to exchange code for token");
      }

      const data = await response.json();
      return data.id_token;
    },
    onSuccess: async (token) => {
      await Preferences.set({
        key: "auth_token",
        value: JSON.stringify(token),
      });
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
    onError: (error) => {
      console.error("Error exchanging code for token:", error);
    },
  });
};
